import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  FETCHING_PAGINATION,
  FETCHING_PAGINATION_FINISHED,
  SHOW_ERROR
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: [],
  message: "",
  fetching_pagination: false,
  view: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCHING_PAGINATION: {
      return { ...state, fetching_pagination: true };
    }
    case FETCHING_PAGINATION_FINISHED: {
      return { ...state, fetching_pagination: false };
    }
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: [...state.loading, action.payload] };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", message: "", loading: state.loading.filter(key => key !== action.payload) };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: "", message: action.payload };
    }
    case SHOW_ERROR:
    case FETCH_ERROR: {
      return { ...state, error: action.payload, message: "" };
    }
    case HIDE_MESSAGE: {
      return { ...state, error: "", message: "" };
    }
    default:
      return state;
  }
}
