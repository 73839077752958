import {FETCH_USER_DETAILS, SET_NON_STAFF} from "../../constants/ActionTypes";

const INIT_STATE = {
    first_name: null,
    last_name: null,
    is_staff: false,
	user_fetched: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_USER_DETAILS: {
            return {...state, ...action.payload, user_fetched: true};
        }
        case SET_NON_STAFF: {
            return {...state, is_staff: false}
        }
        default:
            return state;
    }
}
