import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {Alert, LocaleProvider} from "antd";
import {IntlProvider} from "react-intl";
import Auxiliary from "util/Auxiliary";
import AppLocale from "lngProvider";
// import withTracker from "../../components/WithTracker";

import {setInitUrl, userSignOut, verifyToken, setToken, fetchUserDetails} from "../../appRedux/actions";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";
import axios from '../../util/Api';
import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import BrowserSupport, {detectBrowser} from "react-browser-support";
import asyncComponent from "../../util/asyncComponent";

const minBrowserVersions = {
    chrome: '4.10',
    edge: '6',
    firefox: '19.5',
    ie: '10.0',
    opera: '10.0',
    safari: '10.2',
    ios: '11.0.0'
};

const SignIn = asyncComponent(() => import('../SignIn'));

const RestrictedRoute = ({component: Component, token, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: {from: props.location}
                    }}
                />}
    />;


class App extends Component {
    state = {
        browser: null
    };

    componentDidMount() {
        this.setState({ browser: detectBrowser() })
    }

    setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    setNavStyle = (navStyle) => {
        if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER) {
            document.body.classList.add('full-scroll');
            document.body.classList.add('horizontal-layout');
        } else {
            document.body.classList.remove('full-scroll');
            document.body.classList.remove('horizontal-layout');
        }
    };

    componentWillMount() {
        if (this.props.initURL === '') {
            this.props.setInitUrl(this.props.history.location.pathname);
        }
        const params = new URLSearchParams(this.props.location.search);
        if (params.has('token')) {
	        this.props.setToken(params.get('token'));
            this.props.verifyToken(params.get('token'));
        }
        if (params.has("theme")) {
            this.props.setThemeType(params.get('theme'));
        }
        if (params.has("nav-style")) {
            this.props.onNavStyleChange(params.get('nav-style'));
        }
        if (params.has("layout-type")) {
            this.props.onLayoutTypeChange(params.get('layout-type'));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.token) {
            axios.defaults.headers.common['Authorization'] = "JWT " + nextProps.token;
        }
        if (nextProps.token && !nextProps.user_fetched) {
            this.props.fetchUserDetails();
        }
    }

    renderBrowserSupport = () => (
        <Auxiliary>
            <div>
                We don't support your browser, please upgrade!
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '1em'}}>
                <a href='https://www.google.com/chrome/browser/desktop/index.html'>Download Chrome</a>
                <a href='https://www.mozilla.org/en-US/firefox/new/'>Download Firefox</a>
                <a href='https://safari.en.softonic.com/mac/download'>Download Safari</a>
            </div>
        </Auxiliary>
    );


    render() {
        const {match, location, themeType, layoutType, navStyle, locale, token, initURL} = this.props;

        if (!token && location.pathname !== '/signin') {
            this.props.setInitUrl(location.pathname);
        }

        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme');
        }
	    if (token) {
		    if (location.pathname === '/logout') {
			    this.props.userSignOut();
		    }
		    if (location.pathname === '/signin' || location.pathname === '/' || !location.pathname) {
			    return <Redirect to={initURL || '/articles'}/>
		    }
	    }
        this.setLayoutType(layoutType);

        this.setNavStyle(navStyle);

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <LocaleProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <Auxiliary>
                        <BrowserSupport supported={minBrowserVersions} style={{}}>
                            <Alert
                                type="error"
                                message={this.renderBrowserSupport()}
                                // banner={true}
                            />
                        </BrowserSupport>


                        <Switch>
                            <Route exact path='/signin' component={SignIn}/>
	                        <Route exact path='/manual-signin' component={SignIn}/>
	                        <RestrictedRoute
                                path={`${match.url}`}
                                token={token}
                                component={asyncComponent(() => import('./MainApp'))}
                            />
                        </Switch>
                    </Auxiliary>
                </IntlProvider>
            </LocaleProvider>
        )
    }
}

const mapStateToProps = ({settings, auth, common, userDetails}) => {
	const {locale, navStyle, themeType, layoutType} = settings;
	const {user, profile, token, initURL} = auth;
	const {user_fetched} = userDetails;
	const {redirect} = common;
	return {locale, token, navStyle, themeType, layoutType, user, profile, initURL, redirect, user_fetched}
};
// App = withTracker(App, {});
export default withRouter(connect(mapStateToProps, {
    setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange, userSignOut, setToken, verifyToken, fetchUserDetails
})(App));
