/* eslint-disable no-console */
import {
	FETCH_FACTIVA_QUERIES,
	FETCH_SEARCH_QUERIES,
	FETCH_START,
	FETCH_SUCCESS,
	FETCH_NEWS_FEEDS
} from "../../constants/ActionTypes";
import {fetchError} from "./Common";
import Api from "util/Api";
import * as _ from 'lodash';

export const fetchFactivaQueries = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "factiva_queries"});
			Api
				.get("factiva_queries")
				.then(({data}) => {
					dispatch({type: FETCH_FACTIVA_QUERIES, payload: data});
					dispatch({type: FETCH_SUCCESS, payload: "factiva_queries"});
					resolve();
				})
				.catch((error) => {
					dispatch(fetchError(error));
					dispatch({type: FETCH_SUCCESS, payload: "factiva_queries"});
					reject();
				});
		});
	};
};

export const fetchSearchQueries = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "search_queries"});
			Api
				.get("search_queries")
				.then(({data}) => {
					let grouped = _.groupBy(data, d => d.name);
					grouped = Object.keys(grouped).map(key => ({name: key, ids: grouped[key].map(i => i.id)}));
					dispatch({type: FETCH_SEARCH_QUERIES, payload: grouped});
					dispatch({type: FETCH_SUCCESS, payload: "search_queries"});
					resolve();
				})
				.catch((error) => {
					dispatch(fetchError(error));
					dispatch({type: FETCH_SUCCESS, payload: "search_queries"});
					reject();
				});
		});
	};
};


export const fetchNewsFeeds = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "news_feeds"});
			Api
				.get("feeds")
				.then(({data}) => {
					dispatch({type: FETCH_NEWS_FEEDS, payload: data});
					dispatch({type: FETCH_SUCCESS, payload: "news_feeds"});
					resolve();
				})
				.catch((error) => {
					dispatch(fetchError(error));
					dispatch({type: FETCH_SUCCESS, payload: "news_feeds"});
					reject();
				});
		});
	};
};