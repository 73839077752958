/* eslint-disable no-console */
import {
	FETCH_CATEGORIES,
	FETCH_BUSINESSRADAR_CATEGORIES,
	FETCH_START,
	FETCH_SUCCESS
} from "../../constants/ActionTypes";
import {fetchError} from "./Common";
import Api from "util/Api";
import * as _ from 'lodash';

export const fetchCategories = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "categories"});
			Api
				.get("categories")
				.then(({data}) => {
					dispatch({type: FETCH_CATEGORIES, payload: data});
					dispatch({type: FETCH_SUCCESS, payload: "categories"});
					if (!window.itmc) {
						window.itmc = {}
					}
					window.itmc.categories = data
					document.dispatchEvent(new Event('api-category-loaded'));
					resolve();
				})
				.catch((error) => {
					dispatch(fetchError(error));
					dispatch({type: FETCH_SUCCESS, payload: "categories"});
					reject();
				});
		});
	};
};
export const fetchBusinessradarCategories = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "businessradar_categories"});
			Api
				.get("businessradar_categories")
				.then(({data}) => {
					let categories = _.groupBy(data.sort((a, b) => a.parent_name > b.parent_name), ({parent_name}) => parent_name);
					dispatch({type: FETCH_BUSINESSRADAR_CATEGORIES, payload: categories});
					dispatch({type: FETCH_SUCCESS, payload: "businessradar_categories"});
					resolve();
				})
				.catch((error) => {
					dispatch(fetchError(error));
					dispatch({type: FETCH_SUCCESS, payload: "businessradar_categories"});
					reject();
				});
		});
	};
};