import {ONBOARDING_FINISHED, RESET_ONBOARDING} from "../../constants/ActionTypes";

const INIT_STATE = {
    finished: JSON.parse(localStorage.getItem('onboarding_finished')) || [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ONBOARDING_FINISHED: {
            return {...state, finished: [...state.finished, action.payload]};
        }
        case RESET_ONBOARDING: {
            return {...state, finished: []}
        }

        default:
            return state;
    }
}
