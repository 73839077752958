import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_TOKEN_SET} from "../../constants/ActionTypes";

const INIT_STATE = {
    token: JSON.parse(localStorage.getItem('token')),
    initURL: '',
    user: JSON.parse(localStorage.getItem('user')),
    user_fetched: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_URL: {
            return {...state, initURL: action.payload};
        }

        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                token: null,
                user: null,
                initURL: ''
            }
        }
        case USER_TOKEN_SET: {
            return {
                ...state,
                token: action.payload,
            };
        }

        default:
            return state;
    }
}
