import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Onboarding from "./Onboarding";
import Categories from "./Categories";
import Queries from "./Queries";
import UserDetails from "./UserDetails";

const reducers = combineReducers({
    routing: routerReducer,
    settings: Settings,
    auth: Auth,
    common: Common,
    onboarding: Onboarding,
	categories: Categories,
	queries: Queries,
    userDetails: UserDetails
});

export default reducers;
