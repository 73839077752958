import {
    ONBOARDING_FINISHED,
    RESET_ONBOARDING
} from "../../constants/ActionTypes";


export const onboardingFinished = (onboarding_name) => {
    return (dispatch, getState) => {
        const {onboarding} = getState();
        localStorage.setItem("onboarding_finished",  JSON.stringify([...onboarding.finished, onboarding_name]));
        dispatch({type: ONBOARDING_FINISHED, payload: onboarding_name});
    }
};

export const resetOnboarding = () => {
    localStorage.setItem("onboarding_finished",  JSON.stringify([]));
    return {type: RESET_ONBOARDING};
};