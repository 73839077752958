import {FETCH_FACTIVA_QUERIES, FETCH_SEARCH_QUERIES, FETCH_NEWS_FEEDS} from "../../constants/ActionTypes";

const INIT_STATE = {
    factiva_queries: null,
    search_queries: null,
	news_feeds: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_FACTIVA_QUERIES: {
            return {...state, factiva_queries: action.payload};
        }
	    case FETCH_SEARCH_QUERIES: {
		    return {...state, search_queries: action.payload};
	    }
	    case FETCH_NEWS_FEEDS: {
	    	return {...state, news_feeds: action.payload}
	    }
        default:
            return state;
    }
}
