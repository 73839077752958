/* eslint-disable no-console */
import {
	FETCH_USER_DETAILS,
	FETCH_START,
	FETCH_SUCCESS, SET_NON_STAFF
} from "../../constants/ActionTypes";
import {fetchError} from "./Common";
import Api from "util/Api";

export const fetchUserDetails = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "user_details"});
			Api
				.get("user_details")
				.then(({data}) => {
					dispatch({type: FETCH_USER_DETAILS, payload: data});
					dispatch({type: FETCH_SUCCESS, payload: "user_details"});

					if (!window.itmc) {
						window.itmc = {}
					}
					window.itmc.user = {
						is_staff: data.is_staff,
						department: data.department,
						country: data.country
					}
					resolve();
				})
				.catch((error) => {
					dispatch(fetchError(error));
					dispatch({type: FETCH_SUCCESS, payload: "categouser_detailsries"});
					reject();
				});
		});
	};
};

export const setNonStaff = () => {
	return {type: SET_NON_STAFF}
};