import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, REDIRECT} from "../../constants/ActionTypes";
import {userSignOut} from './Auth';

export const fetchStart = () => {
    return {
        type: FETCH_START
    }
};

export const fetchSuccess = () => {
    return {
        type: FETCH_SUCCESS
    }
};

export const redirect = (url) => {
    return {
        type: REDIRECT,
        payload: url
    }
};

export const fetchError = (error) => {
    return (dispatch) => {
        let message = error.message ? error.message : 'Unknown error';

        if (typeof error === 'string') {
            message = error;
        }

        if (error.response && error.response.data && error.response.data.error) {
            message = error.response.data.error;
        }
        if (error.response && error.response.data && error.response.data.detail) {
            message = error.response.data.detail;
        }

        if (error && error.response) {
            if (error.response.data) {
                const {data} = error.response;
                if (data.type) {
                    // do something smart
                }
            }
            if (message === 'Signature has expired.' || message === 'Error decoding signature.') {
                dispatch({type: FETCH_ERROR, payload: "Session timeout, please login again!"});
                return dispatch(userSignOut());
            }
            if (error.response.status === 400) {
                if (error.response.request.responseURL && error.response.request.responseURL.endsWith('/login')) {
                    return dispatch({type: FETCH_ERROR, payload: "Incorrect credentials: password and/or username do not match!"})
                }
            }
        }

        dispatch({ type: FETCH_ERROR, payload: message});
    };
};

export const showMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    }
};

// @deprecated
export const showError = (message) => {
    return fetchError(message)
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE
    }
};






