/* eslint-disable no-console */
import {
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_TOKEN_SET
} from "../../constants/ActionTypes";
import {fetchError} from "./Common";
import Api from "util/Api";

const EXCLUDED_INIT_URLS = [
	"/", "", "/signin", "/login", "/register", "/change_password", "/profile", "phone_verification", "/phone_verification", '/manual-signin', '/signin/manual'
];

export const setInitUrl = (url) => {
	return (dispatch) => {
		if (EXCLUDED_INIT_URLS.includes(url) || url.includes("/password_forgotten")) {
			return null;
		}
		console.log(`Setting initUrl: ${url}`);
		dispatch({
			type: INIT_URL,
			payload: url
		});
	};
};

export const setToken = (token) => {
	localStorage.setItem("token", JSON.stringify(token));
	Api.defaults.headers.common["Authorization"] = "JWT " + token;
	return {
		type: USER_TOKEN_SET,
		payload: token
	}
};

export const verifyToken = (token) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Api.post(
				'/api-token-verify/',
				{token: token}
				)
				.then(() => {
					resolve();
					dispatch(setToken(token))
				})
				.catch(error => {
					dispatch(fetchError(error));
					dispatch(userSignOut());
					reject(error)
				});
		});
	}
};

export const userSignIn = ({email, password}) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type: FETCH_START, payload: "login"});
			Api.post("api-token-obtain/", {
					username: email,
					password: password
				}
			).then(({data}) => {
				dispatch(setToken(data.token));
				dispatch({type: FETCH_SUCCESS, payload: "login"});
				resolve();
			}).catch(function (error) {
				dispatch(fetchError(error));
				dispatch({type: FETCH_SUCCESS, payload: "login"});
				reject();
			});
		});
	};
};


export const userSignOut = () => {
	return (dispatch) => {
		setTimeout(() => {
			localStorage.removeItem("token");
			dispatch({type: SIGNOUT_USER_SUCCESS});
		}, 1000);
	};
};
