// GENERAL FETCHING (for loading view -> replace current)
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

// GENERAL PAGINATION FETCHING (for loading view -> show current + loading)
export const FETCHING_PAGINATION = 'fetching_pagination';
export const FETCHING_PAGINATION_FINISHED = 'fetching_pagination_finished';

// GENERAL MESSAGE
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const REDIRECT = 'REDIRECT';

// ONBOARDING
export const ONBOARDING_FINISHED = 'ONBOARDING_FINISHED';
export const RESET_ONBOARDING = 'RESET_ONBOARDING';

// AUTHENTICATION
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_BUSINESSRADAR_CATEGORIES = 'FETCH_BUSINESSRADAR_CATEGORIES';
export const SET_NON_STAFF = 'SET_NON_STAFF';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_FACTIVA_QUERIES = 'FETCH_FACTIVA_QUERIES';
export const FETCH_SEARCH_QUERIES = 'FETCH_SEARCH_QUERIES';
export const FETCH_NEWS_FEEDS = 'FETCH_NEWS_FEEDS';