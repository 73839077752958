import {FETCH_CATEGORIES, FETCH_BUSINESSRADAR_CATEGORIES} from "../../constants/ActionTypes";

const INIT_STATE = {
    categories: null,
    businessradar_categories: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES: {
            return {...state, categories: action.payload};
        }
	    case FETCH_BUSINESSRADAR_CATEGORIES: {
		    return {...state, businessradar_categories: action.payload};
	    }
        default:
            return state;
    }
}
